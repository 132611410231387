@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body {
    max-width: 100%;
    /*overflow-x: hidden;*/
    font-size: 16px !important;
    -webkit-text-size-adjust:none;
    -webkit-overflow-scrolling: touch;
    /*line-height: 1rem;*/
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  -webkit-text-size-adjust:none;
}
::-webkit-scrollbar {display:none;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea{font-family: 'Rubik', sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size:16px !important;
    -webkit-text-size-adjust:none;
  }
} 

/*####################################
########### SPLASH SCREEN ############
####################################*/

@-webkit-keyframes fadesplash {
  0%   { opacity: 1; }
  95% { z-index: 9999999;}
  100% { opacity: 0; z-index: -10;}
}
@-moz-keyframes fadesplash{
  0%   { opacity: 1; }
  100% { opacity: 0; z-index: -10;}
}
@-o-keyframes fadesplash {
  0%   { opacity: 1; }
  100% { opacity: 0; z-index: -10;}
}
@keyframes fadesplash {
  0%   { opacity: 1; }

  100% {opacity: 0;z-index: -10;}
}
#splashscreen{
  
  -webkit-animation: fadesplash ease-out .5s 1.2s ; /* Safari 4+ */
  -moz-animation:    fadesplash ease-out .5s 1.2s ; /* Fx 5+ */
    -o-animation:      fadesplash ease-out .5s 1.2s ; /* Opera 12+ */
   animation:         fadesplash ease-out .5s 1.2s ; /* IE 10+, Fx 29+ */
   animation-fill-mode: forwards;
   height: 100%;
width: 100vw;
z-index: 9999999999999999;
  position: fixed !important; top: 0; left: 0;
background-color: rgb(255 , 255, 255);
  object-fit: cover;
text-align: center;
  vertical-align: middle;
}

.splashwraplinks{
  
  -webkit-animation: fadesplash ease-out .5s 1.2s ; /* Safari 4+ */
  -moz-animation:    fadesplash ease-out .5s 1.2s ; /* Fx 5+ */
    -o-animation:      fadesplash ease-out .5s 1.2s ; /* Opera 12+ */
   animation:         fadesplash ease-out .5s 1.2s ; /* IE 10+, Fx 29+ */
   animation-fill-mode: forwards;
   height: 100%;
width: 100vw;
z-index: 9999999999999999;
  position: fixed !important; top: 0; left: 0;
background-color: rgb(255 , 255, 255);
  object-fit: cover;
text-align: center;
}

.splashlogo{
  vertical-align: middle;
  padding-top: 22vh;
  margin-bottom: 1.4rem;
}
.splashlogolinks{
  vertical-align: middle;
  padding-top: 5vh;
  margin-bottom: 1.4rem;
}





.emailsignupinput{
margin-bottom:5px !important
}
.emailsignupsegment{
margin:0 !important;
padding-bottom: 0 !important;
}
.emailsignupinput>div>input{
/*font-size: .9rem !important;*/
padding: 8px !important;
}

.submitemailbutton{
 display: inline-block;
 width: 100%;
 margin-top: 1.5rem !important;
 margin-bottom: 1.5rem !important;
}
.submitemailbutton>button{
 width: 100%;
 background-color: black !important;
 color: whitesmoke !important;
}


.newsletternote{
  text-align: center;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: .5em;
}


.emailvalidationerror>button{
  margin-left: 1rem !important;
}

.emailvalidationerror{
  text-align: center !important;
 top: 20vh;
}
.emailvalidationerrorconf{
  text-align: center !important;
 
}

.confbutton{
  margin-bottom: 1rem !important;
}

.poweredbydiv{
  text-align: center;
  margin: 0 1rem 1.5em 1rem;
  max-width: 4rem;
}
.poweredbyspan{
  color: #006F32;
  font-size: x-small;
}
.poweredbyspanpop{
  color: #000000;
  font-size:xx-small;
}

#restaurantlogo{
  max-height: 80px;
  width: auto;
  margin: auto;
  padding: .8rem 0 .5rem 0;
}
.conflogo{
  max-height: 60px;
  width: auto !important;
  margin: auto;
  padding: .4rem 0 .8rem 2rem;
  display: inline-block !important;
  vertical-align: top !important;
}

.registered{
  background-color: rgb(27, 53, 28);
}
.conflabel{
  font-size: .8rem;
  font-style: italic;
  color: #000000;
}
.conftext{
  color: #006F32;
}
.confloader{
  margin: 0 auto;
  display: inline !important;
}
